<template>
  <div v-if="items.length > 0" class="d-flex">
    <div class="flex-grow-0 d-flex align-center">
      <v-btn
        fab
        depressed
        small
        color="white"
        light
        :disabled="currentIndex == 0"
        @click="onGetPre"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </div>
    <div class="flex-grow-1 d-flex align-center overflow-hidden">
      <div class="overflow-hidden pa-2">
        <v-card
          v-if="item"
          class="overflow-hidden silde-card pa-2"
          height="140px"
          width="100%"
          flat
          outlined
          @click="$emit('click:select', item)"
          :title="item.name + '\n' + item.address"
        >
          <div class="pt-2 full-width text-subtitle-2" :title="item.address">
            <div class="full-weight text-truncate">
              {{ item.address||item.name }}
            </div>
          </div>
          <div class="text-caption">
            <div v-if="item.place">{{ item.place.name }}</div>
            <div
              v-if="item.address"
              class="text-truncate"
              :title="item.address"
            >
              {{ item.address }}
            </div>
            <div>
              <StatusTypeText :value="!item.isClosed" />
            </div>
          </div>
          <div class="pt-1 d-flex">
            <guide-link-button
              x-small
              v-if="item.guideLink"
              :link="item.guideLink"
            />
            <a
              :href="`tel:${item.primaryPhone}`"
              class="text-decoration-none ml-2"
              v-if="item.primaryPhone"
            >
              <v-btn color="primary" class="text-none" x-small rounded text>
                <v-icon left> mdi-phone </v-icon>
                Gọi
              </v-btn>
            </a>
          </div>
        </v-card>
      </div>
    </div>
    <div class="flex-grow-0 d-flex align-center">
      <v-btn
        fab
        depressed
        small
        color="white"
        light
        :disabled="currentIndex >= items.length - 1"
        @click="onGetNext()"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import StatusTypeText from "@/views/StatusTypeText.vue";
import { Swipe } from "@/service/model/Swipe";
export default {
  components: { StatusTypeText },
  props: {
    simple: Boolean,
    items: { type: Array, default: () => [] },
    currentItem: { type: Object },
  },
  data: () => ({ currentIndex: 0 }),
  computed: {
    item() {
      return this.items[this.currentIndex];
    },
  },
  watch: {
    currentItem: {
      handler(value) {
        if (!value) {
          this.currentIndex = 0;
        } else {
          this.currentIndex =
            this.items.findIndex((x) => x.id == value.id) || 0;
        }
        this.onSelect();
      },
      immediate: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.onSetSwipe();
    });
  },
  beforeDestroy() {
    if (this.swipe) {
      this.swipe.destroy();
    }
  },
  methods: {
    onSetSwipe() {
      if (this.swipe) {
        return;
      }
      this.swipe = new Swipe(this.$el);
      this.swipe.onLeft((e) => {
        this.onGetNext();
      });
      this.swipe.onRight((e) => {
        this.onGetPre();
      });
    },
    onGetNext() {
      if (this.currentIndex >= this.items.length - 1) return;
      this.currentIndex++;
      this.onSelect();
    },
    onGetPre() {
      if (this.currentIndex < 1) return;
      this.currentIndex--;
      this.onSelect();
    },
    onSelect() {
      this.$root.$emit(
        "hover:complex-search-items",
        [this.item].reduce((acc, cur) => {
          if (cur && cur.lng && cur.lat) {
            acc.push([cur.lng, cur.lat]);
          }
          return acc;
        }, [])
      );
      this.$root.$emit("fly-to:complex-search-items", [
        this.item.lng,
        this.item.lat,
      ]);
    },
    openLink(link) {
      window.open(link);
    },
  },
};
</script>

<style scoped>
.silde-card {
  border: thin solid rgba(0, 0, 0, 0.24);
}
.active-class {
  border: solid 2px var(--v-primary-base);
}
.silde-card:hover {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
}
.text-truncate-2 {
  display: -webkit-box;
  /* max-width: 200px; */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.5rem;
  margin-bottom: 8px;
}
</style>
